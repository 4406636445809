import React from 'react';
import Typed from 'react-typed';
import video from './../assets/hero-background.mp4';
import p1 from './../assets/300x169/01.jpg';
import p2 from './../assets/300x169/02.jpg';
import p3 from './../assets/300x169/03.jpg';
import p4 from './../assets/300x169/04.jpg';
import p5 from './../assets/300x169/05.jpg';
import p6 from './../assets/300x169/06.jpg';
import p7 from './../assets/300x169/07.jpg';
import p8 from './../assets/300x169/08.jpg';
import p9 from './../assets/300x169/09.jpg';
import p10 from './../assets/300x169/10.jpg';
import p11 from './../assets/300x169/11.jpg';
import p12 from './../assets/300x169/12.jpg';
import {Carousel} from "react-responsive-carousel";

const Hero = () => {
  return (
    <div className='text-white'>
      <video className="background-video" src={video} playsInline autoPlay loop muted/>
      <div className='max-w-[800px] h-screen mt-[-86px] w-full mx-auto text-center flex flex-col justify-center'>
        <h1 className='md:text-6xl sm:text-6xl text-4xl font-bold md:py-6 sm:mt-10'>
          Graubunden Metal
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
            Profesionalitate in
          </p>
          <Typed
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['analiza', 'debitare', 'sudura', 'asamblare', 'montaj']}
            typeSpeed={50}
            backSpeed={50}
            loop
          />
        </div>
        <p id="about" className='md:text-2xl text-xl font-bold text-gray-400'>Suntem o societate românească cu capital integral privat. Compania a fost înființată în anul 2009 și este specializată pe lucrări de anvergură medie în domeniul construcțiilor, în calitate de antreprenor general cât și în calitate de producător de structuri metalice.</p>
        <button className='bg-[#FF4207] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white'>
          <a href="https://stage.shop.graubundenmetal.ro/">Spre magazin</a>
        </button>
        <Carousel className="mx-2 bg-opacity-30 flex-col center p-4"
                  infiniteLoop={true}
                  dynamicHeight={200}
                  showIndicators={true}
                  showThumbs={false}
                  showArrows={true}
                  autoPlay={true}
                  stopOnHover={true}
                  emulateTouch={true}
                  selectedItem={0}
                  swipeable={true}
        >
              <div>
                <img src={p1} alt=""/>
              </div>
              <div>
                <img src={p2} alt=""/>
              </div>
              <div>
                <img src={p3} alt=""/>
              </div>
              <div>
                <img src={p4} alt=""/>
              </div>
              <div>
                <img src={p5} alt=""/>
              </div>
              <div>
                <img src={p6} alt=""/>
              </div>
              <div>
                <img src={p7} alt=""/>
              </div>
              <div>
                <img src={p8} alt=""/>
              </div>
              <div>
                <img src={p9} alt=""/>
              </div>
              <div>
                <img src={p10} alt=""/>
              </div>
              <div>
                <img src={p11} alt=""/>
              </div>
              <div>
                <img src={p12} alt=""/>
              </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Hero;
