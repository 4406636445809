import React from 'react';
import grinzi from './../assets/grinzi.jpg';
import laminate from './../assets/laminate.jpg';
import plase from './../assets/plase-grilaje.jpg';
import profile from './../assets/profile-deschise.jpg';
import table from './../assets/table.jpg';
import tigle from './../assets/tigle.jpg';
import trase from './../assets/trase.jpg';
import tevi from './../assets/tevi.jpg';

const Cards = () => {
    const data = [
        {
            name: 'Grinzi',
            image: grinzi,
            url: 'https://stage.shop.graubundenmetal.ro/category/grinzi-qpk2i'
        },
        {
            name: 'Laminate',
            image: laminate,
            url: 'https://stage.shop.graubundenmetal.ro/category/laminate-1nnzm'
        },
        {
            name: 'Plase si Grilage',
            image: plase,
            url: 'https://stage.shop.graubundenmetal.ro/category/plase-si-grilaje-ps8fu'
        },
        {
            name: 'Tevi',
            image: tevi,
            url: 'https://stage.shop.graubundenmetal.ro/category/tevi-pqlxa'
        },
        {
            name: 'Trase',
            image: trase,
            url: 'https://stage.shop.graubundenmetal.ro/category/trase-lhrzq'
        },
        {
            name: 'Tabla COILS/TREN',
            image: table,
            url: 'https://stage.shop.graubundenmetal.ro/category/tabla-coilstren-k3c8a'
        },
        {
            name: 'Tigle si panouri de acoperire',
            image: tigle,
            url: 'https://stage.shop.graubundenmetal.ro/category/tigle-si-panouri-de-acoperis-jstqa'
        },
        {
            name: 'Profile Deschise',
            image: profile,
            url: 'https://stage.shop.graubundenmetal.ro/category/profile-deschise-z49p9'
        },
    ]

    return (
        <div className='w-full py-[2rem] px-4 bg-white' id="produse">
            <h1 className='md:text-5xl sm:text-6xl text-4xl font-bold md:py-6 text-center'>
                Produse
            </h1>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
                {data.map((el, index) => (
                    <div key={index}
                         className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                        <a href={el.url}>
                            <img src={el.image} alt=""/>
                        </a>
                        <a href={el.url} style={{display:"flex", justifyContent:'center', alignItems:'center'}} >
                            <button className='bg-[#FF4207] w-[200px] text-white rounded-md font-medium my-6 mx-auto px-6 py-3'>
                                {el.name}
                            </button>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Cards;
