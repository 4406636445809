import React from 'react';
import {
    FaFacebookSquare,
    FaInstagram,
    FaTwitterSquare, FaYoutubeSquare,
} from 'react-icons/fa';
import logo from "../assets/logo.png";

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
          <img src={logo} alt="logo"/>
        <p className='py-4'>Suntem o societate românească cu capital integral privat. Compania a fost înființată în anul 2009 și este specializată pe lucrări de anvergură medie în domeniul construcțiilor, în calitate de antreprenor general cât și în calitate de producător de structuri metalice.

            Oferim soluții moderne în conformitate cu cerințele pieței, a cerințelor clienților și a standardelor de calitate naționale și internaționale.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            <FaYoutubeSquare size={30} />
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6'>
    <div>
        <h6 className='font-medium text-gray-400' id="contact">Contactati-ne</h6>
        <ul>
            <li className='py-2 text-sm'>Soseaua Vrancei km 3, Loc Odobești, Focșani 620055</li>
            <li className='py-2 text-sm'>
                <a href="mailto:adimn@graubundenmetal.ro">adimn@graubundenmetal.ro</a>
            </li>
            <li className='py-2 text-sm'>
                <a href="tel:+40 742 80 80 90">+40 742 80 80 90</a>
            </li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-400'>Legal</h6>
        <ul>
            <li className='py-2 text-sm'>
                <a href="https://stage.shop.graubundenmetal.ro/terms-and-conditions">
                    Termeni si conditii
                </a>
            </li>
            <li className='py-2 text-sm'>
                <a href="https://stage.shop.graubundenmetal.ro/return-policy">
                    Politica de retur
                </a>
            </li>
            <li className='py-2 text-sm'>
                <a href="https://stage.shop.graubundenmetal.ro/warranty-policy">
                    Politica de garantie
                </a>
            </li>
            <li className='py-2 text-sm'>
                <a href="https://stage.shop.graubundenmetal.ro/privacy-policy">
                    GDPR
                </a>
            </li>
        </ul>
    </div>
      </div>
    </div>
  );
};

export default Footer;
