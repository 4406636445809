import React, {useState} from 'react';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import logo from './../assets/logo.png';

const Navbar = (props) => {
    const nav = props.state;

    return (
        <div className='bg-[#060504] bg-opacity-60 z-50 flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white sticky top-0'>
            <img src={logo} alt="logo"/>
            <ul className='hidden md:flex'>
                <li className='p-4'>
                    <a href="#">
                        Acasa
                    </a></li>
                <li className='p-4'>
                    <a href="#about">
                        Despre noi
                    </a></li>
                <li className='p-4'>
                    <a href="#produse">
                        Produse
                    </a></li>
                <li className='p-4'>
                    <a href="https://prof-con.ro/">
                        Hale
                    </a></li>
                <li className='p-4'>
                    <a href="#contact">
                        Contact
                    </a>
                </li>
                <li className='p-4'>
                    <a href="https://stage.shop.graubundenmetal.ro/user/login">
                        Login
                    </a>
                </li>
            </ul>
            <div className='block md:hidden'>
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
            </div>
            <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                <img src={logo} alt="logo" className='max-w-[150px] py-4 px-4'/>
                <li className='p-4 border-b border-gray-600'><a href="#">
                    Acasa
                </a></li>
                <li className='p-4 border-b border-gray-600'><a href="#about">
                    Despre noi
                </a></li>
                <li className='p-4 border-b border-gray-600'><a href="https://prof-con.ro/">Hale</a></li>
                <li className='p-4 border-b border-gray-600'><a href="#produse">
                    Produse
                </a></li>
                <li className='p-4 border-b border-gray-600'><a href="#contact">
                    Contact
                </a></li>
            </ul>
        </div>
    );
};

export default Navbar;
