import React, {useEffect, useState} from 'react';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';

function App() {
    const [nav, setNav] = useState(false);
    useEffect(() => {
        const onClickEvent = window.addEventListener('click', (e) => {
            if(e.target.tagName === 'svg') {
                setNav(!nav)
            } else if (nav && e.target.tagName !== 'svg') {
                setNav(false);
            }
        })

        return () => {
            window.removeEventListener('click',onClickEvent);
        }
    })
    return (
        <div>
            <Navbar state={nav}/>
            <Hero/>
            <Cards/>
            <Footer/>
        </div>
    );
}

export default App;
